<template>
  <div>
    <el-dialog title="所需物料" :visible.sync="selfShow" :close-on-click-modal="false">
      <div>
        <vxe-grid
          ref="refTable"
          :size="tableConfig.size"
          :border="tableConfig.border"
          :min-height="tableConfig.minHeight"
          :edit-config="tableConfig.editConfig"
          :row-config="tableConfig.rowConfig"
          :mouse-config="tableConfig.mouseConfig"
          :keyboard-config="tableConfig.keyboardConfig"
          :columns="tableColumn"
          :data="selfData"
        />
      </div>
      <div slot="footer">
        <el-button size="small" @click="popupCancel">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfData: [],
      tableColumn: [
        {
          title: '物料号',
          field: 'wu_liao_code'
        },
        {
          title: '品名',
          field: 'craft'
        },
        {
          title: '工艺',
          field: 'process_name'
        },
        {
          title: '材质',
          field: 'texture'
        },
        {
          title: '规格',
          field: 'sales_specification'
        },
        {
          title: '单位',
          field: 'unit'
        },
        {
          title: '入库数量',
          field: 'ru_ku_stock_counts'
        },
        {
          title: '仓库',
          field: 'cang_ku'
        }
      ]
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let obj = {}
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getListData()
  },
  methods: {
    // 获取列表数据
    getListData() {
      let params = {
        need_wu_liao_ids: this.data.need_wu_liao_code.join(',')
      }
      this.$api({
        method: 'get',
        url: '/admin/gcvip/factory/getNeedWuLiao',
        params
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.selfData = res.data.need_list
        }
      })
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="less" scoped></style>
