const constant = {
  // 表格配置
  tableConfig: {
    border: true,
    size: 'mini',
    'highlight-hover-row': true,
    data: [],
    columns: [],
    height: '',
    'export-config': { type: ['xlsx'], types: ['xlsx'] },
    'checkbox-config': { labelField: 'id', range: true }
  },
  // 分页
  page: {
    size: 'default',
    total: 0,
    currentPage: 1,
    pageSize: 50,
    pageSizes: [10, 20, 50, 100, 200, 500],
    layouts: [
      'Sizes',
      'PrevJump',
      'PrevPage',
      'Number',
      'NextPage',
      'NextJump',
      'FullJump',
      'Total'
    ]
  },
  pageMax: 999999,
  // 菜单导航
  navList: [
    {
      name: '首页',
      path: '/home',
      control: [],
      isShow: true,
      children: []
    },
    {
      name: '销售管理',
      path: '/sale',
      control: [],
      isShow: false,
      children: [
        {
          name: '订单管理',
          path: '/sale/order/list',
          control: ['/checkBill'],
          isShow: false
        },
        {
          name: '客户管理',
          path: '/sale/customer/list',
          control: ['/customers'],
          isShow: false
        }
      ]
    },
    {
      name: '采购管理',
      path: '/purchase',
      control: [],
      isShow: false,
      children: [
        {
          name: '采购列表',
          path: '/purchase/apply-purchase/list',
          control: ['/shengou', '/caigou'], //purchase
          isShow: false
        }
      ]
    },
    {
      name: '财务管理',
      path: '/finance',
      control: [],
      isShow: false,
      children: [
        {
          name: '应收列表',
          path: '/finance/payin/list',
          control: ['/reportBill'],
          isShow: false
        },
        {
          name: '应付列表',
          path: '/finance/payout/list',
          control: ['/reportBill'],
          isShow: false
        },
        {
          name: '银行账户列表',
          path: '/finance/bank/list',
          control: ['/reportBill'],
          isShow: false
        }
      ]
    },
    {
      name: '库存管理',
      path: '/stock',
      control: [],
      isShow: false,
      children: [
        {
          name: '库存列表',
          path: '/stock/stock/list',
          control: ['/stock'],
          isShow: false
        },
        {
          name: '库存列表-旧版',
          path: '/stock/stock/old-list',
          control: ['/stock'],
          isShow: false
        },
        {
          name: '入库列表',
          path: '/stock/stock-in/wait/list',
          control: ['/ruku'],
          isShow: false
        },
        {
          name: '出库列表',
          path: '/stock/stock-out/wait/list',
          control: ['/chuKu'],
          isShow: false
        },
        {
          name: '仓库列表',
          path: '/stock/warehouse/list',
          control: ['/warehouseSetting'],
          isShow: false
        }
      ]
    },
    {
      name: '生产管理',
      path: '/production',
      control: [],
      isShow: false,
      children: [
        {
          name: '生产列表',
          path: '/production/order/list',
          control: ['/factory'],
          isShow: false
        },
        {
          name: '工单列表',
          path: '/production/process/list',
          control: ['/gongDan'],
          isShow: false
        },
        {
          name: '任务列表',
          path: '/production/task/list',
          control: ['/gongDan'],
          isShow: false
        }
      ]
    },
    {
      name: '商品设置',
      path: '/goods',
      control: [],
      isShow: false,
      children: [
        {
          name: '商品类目管理',
          path: '/goods/category/manage',
          control: ['/columnData'],
          isShow: false
        }
      ]
    },
    {
      name: '基础设置',
      path: '/base',
      control: [],
      isShow: false,
      children: [
        {
          name: '供应商列表',
          path: '/base/supplier/list',
          control: ['/supplier'],
          isShow: false
        },
        {
          name: '加工商列表',
          path: '/base/processor/list',
          control: ['/processor'],
          isShow: false
        },
        {
          name: '类别列表',
          path: '/base/category/list',
          control: ['/categorySetting'],
          isShow: false
        },
        {
          name: '参数列表',
          path: '/base/dict/list',
          control: ['/gcvipDict'],
          isShow: false
        },
        {
          name: '单位列表',
          path: '/base/unit/list',
          control: ['/unitSetting'],
          isShow: false
        },
        {
          name: '地址列表',
          path: '/base/address/list',
          control: ['/address'],
          isShow: false
        },
        {
          name: '印章列表',
          path: '/base/seal/list',
          control: ['/yinZhang'],
          isShow: false
        }
      ]
    },
    {
      name: '系统设置',
      path: '/system',
      control: [],
      isShow: false,
      children: [
        {
          name: '账户列表',
          path: '/system/account/list',
          control: ['/account'],
          isShow: false
        },
        {
          name: '角色列表',
          path: '/system/role/list',
          control: ['/admin/role/editVue'],
          isShow: false
        },
        {
          name: '操作日志列表',
          path: '/system/logger/list',
          control: ['/logger'],
          isShow: false
        }
      ]
    },
    {
      name: '考勤管理',
      path: '/attendance',
      control: [],
      isShow: false,
      children: [
        {
          name: '考勤列表',
          path: '/attendance/attendance/list',
          control: ['/dingding/search'],
          isShow: false
        },
        {
          name: '调休列表',
          path: '/attendance/adjustment/list',
          control: ['/dingding/searchTiaoXiu'],
          isShow: false
        }
      ]
    }
  ],
  // 表格默认配置
  tableDefaultConfig: {
    size: 'mini', // 尺寸
    border: true, // 边框
    minHeight: '64', // 72
    // 配置-工具栏
    toolbarConfig: {
      enabled: false // 是否开启
    },
    // 配置-编辑
    editConfig: {
      trigger: 'click', // 单击
      mode: 'cell' // 单元格
    },
    // 配置-行
    rowConfig: {
      isHover: true, // 鼠标经过
      isCurrent: true, // 高亮
      useKey: true // 是否需要为每一列的 VNode 设置 key 属性
    },
    // 配置-鼠标
    mouseConfig: {
      selected: true // 设置选中单元格
    },
    // 配置键盘
    keyboardConfig: {
      enterToTab: true, // 是否将回车键行为改成 Tab 键行为
      isArrow: true, // 开启方向键功能
      isEnter: true, // 开启回车键功能
      isTab: true, // 开启Tab键功能
      isEdit: true // 开启任意键进入编辑（功能键除外）
    }
  },
  // 核算单-销售表格
  tableSaleObj: {
    wu_liao_code: '', // 物料号
    need_wu_liao_code: [], // 所需物料号
    wu_liao_cut_code: [], // 切管物料号
    category: '', // 品类
    craft: '', // 品名
    texture: '', // 材质
    sales_specification: '', // 规格
    counts_unit: '', // 每套零件数量
    counts: '', // 数量
    unit: '', // 单位
    cost_price_unit: '', // 每件材料成本
    process_price_unit: '', // 每件加工组装
    process_price: '', // 所需加工组装
    sales_price: '', // 销售单价
    sales_amount: '', // 销售金额
    profit_row_unit: '', // 每件利润
    profit_row: '', // 合计利润
    profit_rate: '', // 利润率
    photo_list: [], // 产品图片

    // 原材料计算
    material_length: '', // 原料长度 6000
    parts_length: '', // 零件长度 6000
    tailing_length: '', // 尾料长度
    today_base_price: '', // 今日基价 14
    single_weight: '', // 支重
    material_price: '', // 材料单价
    is_lock_material_price: false, // 是否锁定材料单价
    parts_count: '', // 每支可切数量
    material_counts: '', // 所需材料数量
    is_lock_material_counts: false, // 是否锁定所需材料数量
    material_sales_amount: '', // 所需材料成本
    material_photo_list: [], // 图纸
    picture_text: '', // 附言
    test_list: [
      {
        test_name: '',
        test_action: '',
        test_rule: '',
        test_scale: '',
        reference_standard: '',
        remark: ''
      }
    ],

    // 加工组装计算
    process_list: [
      {
        wu_liao_code: '', // 物料号
        need_wu_liao_code: [], // 所需物料号
        process_order_name: '', // 工序
        process_name: '', // 加工工艺
        process_price: '', // 加工单价
        process_count: '', // 数量
        process_amount: '', // 加工金额
        picture_list: [], // 图纸
        picture_text: '', // 附言
        test_list: [
          {
            test_name: '',
            test_action: '',
            test_rule: '',
            test_scale: '',
            reference_standard: '',
            remark: ''
          }
        ] // 检测标准
      }
    ],

    tableBomData: [] // bom表数据列表
  },
  // 核算单-加工表格
  tableProcessObj: {
    wu_liao_code: '', // 物料号
    need_wu_liao_code: [], // 所需物料号
    process_order_name: '', // 工序
    process_name: '', // 加工工艺
    process_price: '', // 加工单价
    process_count: '', // 数量
    process_amount: '', // 加工金额
    picture_list: [], // 图纸
    picture_text: '', // 附言
    test_list: [] // 检测标准
  },
  // 核算单-检测表格
  tableTestObj: {
    test_name: '', // 检测项目
    test_action: '', // 检测方法
    test_rule: '', // 检测标准
    test_scale: '', // 检测比例
    reference_standard: '', // 参考标准
    remark: '' // 备注
  },
  // 核算单-开单公司
  companyList: [
    {
      key: '佛山市金宏旺金属材料有限公司',
      val: '佛山市金宏旺金属材料有限公司'
    },
    {
      key: '东莞市亿宏金属材料有限公司',
      val: '东莞市亿宏金属材料有限公司'
    }
  ]
}

export default constant
